<template>
  <div class="index"></div>
</template>
    
    <script>
import { postcollaborate_pay, getopenid } from "../../api/logo"; //获取登录信息状态
export default {
  data() {
    return {
      jm_str: "",
      token: "",
      userhome_id: "",
      paycode: "",
      totalFee: "",
      orderNumber: "",
      differentiate: "",
      //-----------
      appid: "wxe9213c94abc0fd5c",
      appsecret: "2f3d7d541a9384924944eb73928f00a8",
      openid: "",
    };
  },
  created() {
    localStorage.setItem("token", this.$route.query.token);

    let code = this.getUrlKey("code");
    let state = this.getUrlKey("state");

    if (code) {
      let params = {
        code: code,
        state: state,
      };

      getopenid(params).then((res) => {
        if (res.code == 200) {
          this.userhome_id = state;
          this.differentiate = obj.differentiate;

          if (obj.differentiate == 0) {
            this.paycode = obj.paycode;
            // alert(this.differentiate);
            // alert(obj.differentiate);

            this.shoppingMet(0);
          } else if (this.differentiate == 1) {
            this.totalFee = obj.totalFee;
            this.orderNumber = obj.orderNumber;
            this.vippayMet();
          } else if (this.differentiate == 2) {
            this.totalFee = obj.totalFee;
            this.orderNumber = obj.orderNumber;
            this.cashpledgeMet();
          }
        }
      });
    } else {
      this.userhome_id = this.$route.query.userId;
      this.totalFee = this.$route.query.totalFee;
      this.orderNumber = this.$route.query.orderNumber;
      this.differentiate = this.$route.query.differentiate;
      if (this.differentiate == 0) {
      } else if (this.differentiate == 1) {
      } else if (this.differentiate == 2) {
        this.cashpledgeMet();
      }
    }
  },
  methods: {
    // 获取code
    // aaa() {
    //   let obj = {
    //     appId: "wxe9213c94abc0fd5c",
    //     timeStamp: "1719073630",
    //     nonceStr: "cc4c772b18cd4e19a9931c3fcf7712c0",
    //     package: "prepay_id=wx23002710226247d397164527f1f5cd0000",
    //     signType: "RSA",
    //     paySign:
    //       "I2tIv8NS95gJ+GP2rVIQdSXZw+VEoIggZNt1fO1cKLUxdjj227gEIa6ou9wGPFFDUEJEnQGuSgjCjHrTa85MiiTI2i2XkJUy3gfwKoZlUCye1ty+KiOUXtw4fo6CNjPCATgwCy1g1gPfIc9vcf6PTnzeji3E0Q1KIkfGHwyZJEbVDZHSe2we9eTdCisSBoNWT2lLy+0jDa2E8bL483BMCAruJ6NE0dwIHdjAQ2KsX52CZmC0C8uJvCsz22nNvg7IBNg2ksmSq/pEleJw1agmYCPpbExhKEHaN5zSjsUY8SdS+SesmEjrqWay/cK/xeDqA5fdgh4/K97p9KSIEcESuA==",
    //   };
    //   this.onBridgeReady(obj);
    // },

    //-------------------------------测试环境模块
    // 押金支付
    cashpledgeMet() {
      let params = {
        //----------------请求参数
        orderNum: this.orderNumber,
        total_fee: this.totalFee,
        platform: "Yinsheng",
        way: "scan",
        channel: "wechat",
      };

      postcollaborate_pay(params).then(
        (res) => {
          console.log("获取", res);
          if (res.code == 200) {
            let obj = JSON.parse(res.content.bizResponseJson.payData);
            console.log("获取obj", obj);
            this.onBridgeReady(obj);
          }
        },
        (err) => {
          this.getCodeApi();
        }
      );
    },

    //-------------------功能性开始
    getCodeApi() {
      //获取code
      let obj = {
        orderNumber: this.orderNumber,
        totalFee: this.totalFee,
        differentiate: 2,
        state: this.userhome_id,
      };

      let str = JSON.stringify(obj);
      let urls = "https://activity.xmw1688.cn/";

      let url =
        "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
        this.appid +
        "&redirect_uri=" +
        urls +
        "&response_type=code&scope=snsapi_base&state=" +
        str +
        "#wechat_redirect";

      window.location.href = url;
    },
    getUrlKey: function (name) {
      //获取url 参数
      return (
        decodeURIComponent(
          (new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
            location.href
          ) || [, ""])[1].replace(/\+/g, "%20")
        ) || null
      );
    },

    //---------------------------------
    // 调取支付
    onBridgeReady(item) {
      WeixinJSBridge.invoke(
        "getBrandWCPayRequest",
        {
          appId: item.appId, //公众号名称，由商户传入
          timeStamp: item.timeStamp, //时间戳，自1970年以来的秒数
          nonceStr: item.nonceStr, //随机串
          package: item.package,
          signType: item.signType, //微信签名方式：
          paySign: item.paySign, //微信签名
        },
        function (res) {
          if (res.err_msg == "get_brand_wcpay_request:ok") {
          } else {
          } // 使用以上方式判断前端返回,微信团队郑重提示：res.err_msg将在用户支付成功后返回    ok，但并不保证它绝对可靠。
        }
      );
    },
  },
};
</script>
    
    <style></style>