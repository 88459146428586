// 登录界面接口

import axios from "./request";

// 微信支付三个接口
export const postwxnew_pay = (data) =>
    axios({
        url: '/new_pay/order_pay',
        method: "post",
        data,
    });

// -----------聚合支付宝PC支付 VIP
export const postVipnew_pay = (data) =>
    axios({
        url: '/new_pay/vip_pay',
        method: "post",
        data,
    });

// -----------聚合支付宝PC支付 押金
export const postCashearnest_pay = (data) =>
    axios({
        url: '/new_pay/earnest_pay',
        method: "post",
        data,
    });


// -----------合伙人-----------------聚合支付宝PC支付 押金
export const postcollaborate_pay = (data) =>
    axios({
        url: '/collaborate/earnest_pay',
        method: "post",
        data,
    });

//测试1
export const getopenid = (params) =>
    axios({
        url: '/new_pay/openid',
        method: "get",
        params
    });
